import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import './assets/style.scss'
import './assets/responsive.scss'
import VuePrism from 'vue-prism'
Vue.use(VuePrism)
import 'prismjs/themes/prism-dark.css'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  VuePrism,
  render: h => h(App)
}).$mount('#app')
