import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
    themes: {
        light: {
        primary: '#6C2196',
        error: '#E1134F',
        success: '#2D9A43',
        purple: '#6C2196',
        green: '#2D9A43',
        blue: '#005CAB',
        red: '#E1134F',
        white: '#ffffff', 
        dark: '#636466',
        },
    },
    },
});
