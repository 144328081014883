import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactPage.vue')
  },
  
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import('../views/AlertsPage.vue')
  },

  {
    path: '/buttons',
    name: 'buttons', 
    component: () => import('../views/ButtonsPage.vue')
  },

  {
    path: '/footers',
    name: 'footers',
    component: () => import('../views/FooterPage.vue')
  },

  {
    path: '/inputs',
    name: 'inputs',
    component: () => import('../views/InputControls.vue')
  },

  {
    path: '/grids',
    name: 'grids',
    component: () => import('../views/GridsPage.vue')
  },

  {
    path: '/icons',
    name: 'icons',
    component: () => import('../views/IconsPage.vue')
  },
  
  {
    path: '/images',
    name: 'images',
    component: () => import('../views/ImagesPage.vue')
  },

  {
    path: '/lists',
    name: 'lists',
    component: () => import('../views/ListsPage.vue')
  },

  {
    path: '/timelines',
    name: 'timelines',
    component: () => import('../views/TimelinesPage.vue')
  }
  ,
  {
    path: '/tabs',
    name: 'tabs',
    component: () => import('../views/TabsPage.vue')
  },
  {
    path: '/progress',
    name: 'progress',
    component: () => import('../views/ProgressPage.vue')
  },
  {
    path: '/pagination',
    name: 'pagination',
    component: () => import('../views/PaginationPage.vue')
  }
  ,
  {
    path: '/overlay',
    name: 'overlay',
    component: () => import('../views/OverlayPage.vue')
  }
  ,
  {
    path: '/navigation',
    name: 'navigation',
    component: () => import('../views/NavigationPage.vue')
  },

  {
    path: '/Cards',
    name: 'cards',
    component: () => import('../views/Cards.vue')
  },
  {
    path: '/Carousels',
    name: 'Carousels',
    component: () => import('../views/Carousels.vue')
  },
  {
    path: '/DatePickers',
    name: 'DatePickers',
    component: () => import('../views/DatePickers.vue')
  },
  {
    path: '/Dividers',
    name: 'Dividers',
    component: () => import('../views/Dividers.vue')
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('../views/TypographyPage.vue')
  },
  {
    path: '/colors',
    name: 'colors',
    component: () => import('../views/ColorsPage.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
