<template>
    <v-card elevation="0" width="100%" >
        <router-link to="/">
            <v-list-item class="top-side">
            <v-list-item-content>
                <v-list-item-title>
                Embryo Options UI Kit
                </v-list-item-title>
                <v-list-item-subtitle>
                V1.0, November 2022
                </v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
         </router-link>
        
        <ul class="menu-items">  
            <li> 
                <router-link to="/alerts">Alerts</router-link>
            </li> 
            <li> 
                <router-link to="/buttons">Buttons</router-link>
            </li> 
            <li> 
                <router-link to="/Cards">Cards</router-link>
            </li> 
            <li> 
                <router-link to="/Carousels">Carousels</router-link>
            </li> 
            <li> 
                <router-link to="/DatePickers">Date Pickers</router-link>
            </li> 
            <li> 
                <router-link to="/Dividers">Dividers</router-link>
            </li> 
            <li> 
                <router-link to="/footers">Footers</router-link>
            </li> 
            <li> 
                <router-link to="/inputs">Form Input & Controls</router-link>
            </li> 
            <li> 
                <router-link to="/grids">Grids</router-link>
            </li> 
            <li> 
                <router-link to="/icons">Icons</router-link>
            </li> 
            <li> 
                <router-link to="/images">Images</router-link>
            </li> 
            <li> 
                <router-link to="/lists">Lists</router-link>
            </li> 
            <li> 
                <router-link to="/navigation">Navigation Drawers</router-link>
            </li> 
            <li> 
                <router-link to="/overlay">Overlays</router-link>
            </li> 
            <li> 
                <router-link to="/pagination">Pagination</router-link>
            </li> 
            <li> 
                <router-link to="/progress">Progress</router-link>
            </li> 
            <li> 
                <router-link to="/tabs">Tabs</router-link>
            </li> 
            <li> 
                <router-link to="/timelines">Timelines</router-link>
            </li>  
            <li> 
                <router-link to="/typography">Typography</router-link>
            </li> 
            <li> 
                <router-link to="/colors">Color Palette</router-link>
            </li> 
        </ul>
    </v-card>
  </template>
  
  
  <script>
  export default {
    name: 'SideBar',
  }
  </script>
  
  <style scoped>
  
  </style>