<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer app v-bind:width="275"  v-model="drawer"
      fixed
      temporary>
       
        <Sidebar />
      </v-navigation-drawer>
      <v-app-bar app class="mobile-header">
        <div class="top-header">
          <router-link to="/"> <img src="@/assets/images/smLogo.png" /> </router-link>
           <v-app-bar-nav-icon @click.stop="drawer = !drawer"> 
              <v-icon
              >
                mdi-menu
              </v-icon>  
            </v-app-bar-nav-icon>
        </div>  
      </v-app-bar>
      <v-main>
        <div class="padding-section">
          <router-view></router-view>
        </div>
      </v-main>
    </v-app>
  </div>
  </template>



<script>
import Sidebar from './components/Sidebar.vue'
export default {
  data () {
      return {
        drawer: null,
      }
    },
  name: 'App',

  components: {
    Sidebar
  }
}
</script>
